<template>
  <div class="company-people">
    <h2 class="detail-header">
      Company People
    </h2>
    <div class="company-people-wrapper mt-4">
      <div v-if="ceo" class="ceo-wrapper">
        <official-card :official="ceo" />
      </div>
      <div v-if="officialsToShow()" class="beneficial-owners-wrapper">
        <div>
          <p class="beneficial-owners-header">
            Beneficial Owners
          </p>
          <div class="officers-wrapper">
            <template v-for="(officer, index) in officers">
              <official-card :key="index" :official="officer" />
            </template>
          </div>
        </div>
        <div v-if="members" class="members-wrapper">
          <p class="beneficial-header">
            {{ getMemberTitle }}
          </p>
          <div class="row">
            <div
              v-for="(member, index) in members"
              :key="index"
              class="col-xl-6 col-lg-12 col-md-12 col-sm-12 mb-3"
            >
              <official-card :key="index" :official="member" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'CompanyPeople',
  components: {
    OfficialCard: () => import('@/components/CompanyDetails/OfficialCard.vue'),
  },
  computed: {
    ...mapGetters('companyDetails', ['dataMapDetails']),
    officers() {
      const officerTitles = ['officer', 'president', 'secretary', 'treasurer', 'official', 'director']
      const officers = this.dataMapDetails.officials?.filter(({ role }) => officerTitles.includes(role?.toLowerCase()))
      return officers?.length ? officers : false
    },
    getMemberTitle() {
      const isMembers = this.dataMapDetails.officials?.some(official => official?.role.toLowerCase() === 'member')
      return isMembers ? 'Members' : 'Managers'
    },
    members() {
      const memberTitles = ['member', 'manager']
      const members = this.dataMapDetails.officials?.filter(({ role }) => memberTitles.includes(role?.toLowerCase()))
      return members?.length ? members : false
    },
    ceo() {
      return this.dataMapDetails.officials?.find(({ role }) => role?.toLowerCase() === 'ceo')
    },
  },
  methods: {
    officialsToShow() {
      return this.officers || this.members
    },
  },
}
</script>

<style lang="scss" scoped>
// Typography: Mixin
@mixin typography($font-size: 1.5em, $font-weight: $ct-ui-font-weight-7, $line-height: 130%) {
  font-family: "Open Sans", "Roboto", sans-serif;
  font-size: $font-size;
  font-weight: $font-weight;
  color: #363636;
  line-height: $line-height;
}

.company-people {

  h2.detail-header {
    @include typography();
  }

  .company-people-wrapper {

    .beneficial-owners-wrapper {
      border-radius: 0.625em;
      background: #F4F4F5;
      padding: 1.0em;

      p.beneficial-owners-header {
        @include typography();
      }

      p.beneficial-header {
        @include typography();
        padding-left: 0.325em;
        margin-bottom: 0.5em !important;
      }

      .members-wrapper {
        border-radius: 0.625em;
        background: #E9E9EA;
        padding: 0.5em 1.0em 0 1.0em;
      }
    }
  }
}
</style>
