var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "company-people" }, [
    _c("h2", { staticClass: "detail-header" }, [
      _vm._v("\n    Company People\n  "),
    ]),
    _c("div", { staticClass: "company-people-wrapper mt-4" }, [
      _vm.ceo
        ? _c(
            "div",
            { staticClass: "ceo-wrapper" },
            [_c("official-card", { attrs: { official: _vm.ceo } })],
            1
          )
        : _vm._e(),
      _vm.officialsToShow()
        ? _c("div", { staticClass: "beneficial-owners-wrapper" }, [
            _c("div", [
              _c("p", { staticClass: "beneficial-owners-header" }, [
                _vm._v("\n          Beneficial Owners\n        "),
              ]),
              _c(
                "div",
                { staticClass: "officers-wrapper" },
                [
                  _vm._l(_vm.officers, function (officer, index) {
                    return [
                      _c("official-card", {
                        key: index,
                        attrs: { official: officer },
                      }),
                    ]
                  }),
                ],
                2
              ),
            ]),
            _vm.members
              ? _c("div", { staticClass: "members-wrapper" }, [
                  _c("p", { staticClass: "beneficial-header" }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.getMemberTitle) + "\n        "
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "row" },
                    _vm._l(_vm.members, function (member, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass:
                            "col-xl-6 col-lg-12 col-md-12 col-sm-12 mb-3",
                        },
                        [
                          _c("official-card", {
                            key: index,
                            attrs: { official: member },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }